import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Download, Github, Twitter, Check, Eye } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Tooltip } from './components/ui/tooltip';

const FILE_SIZE_LIMIT = 1000 * 1024 * 1024; // 100MB in bytes

const App = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileLink, setFileLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl(process.env.REACT_APP_API_URL || 'http://localhost:3001');
  }, []);

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => setCopied(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [copied]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.size > FILE_SIZE_LIMIT) {
      setError(`File size exceeds ${FILE_SIZE_LIMIT / (1024 * 1024)}MB limit. Upgrade to Pro for larger uploads.`);
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Please select a file first.');
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(`${baseUrl}/files`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUploadedFile(selectedFile);
      setFileLink(response.data.fileUrl);
    } catch (err) {
      console.error('Upload error:', err);
      setError(err.response?.data?.error || 'File upload failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(fileLink);
    setCopied(true);
  };

  const handlePreview = () => {
    window.open(fileLink, '_blank');
  };

  const handleDownload = () => {
    window.open(`${fileLink}?download=true`, '_blank');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white text-gray-800 flex flex-col justify-between">
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-5xl font-bold text-center mb-12 text-blue-600">DocuHost</h1>
        
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-8">
          <div className="mb-6">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="file-upload">
                Upload (Max: Free 1GB, Pro 10GB)
              </label>
              <Tooltip content="coming soon...">
                <span className="inline-block bg-yellow-400 text-yellow-800 px-2 py-1 rounded text-xs font-semibold cursor-not-allowed animate-pulse hover:animate-none">
                  Upgrade
                </span>
              </Tooltip>
            </div>
            <div className="relative">
              <input
                id="file-upload"
                type="file"
                onChange={handleFileSelect}
                className="hidden"
                disabled={isLoading}
              />
              <label
                htmlFor="file-upload"
                className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="truncate">
                  {selectedFile ? selectedFile.name : "No file selected"}
                </span>
                <span className="text-blue-600">Browse</span>
              </label>
            </div>
          </div>
          
          {selectedFile && !uploadedFile && (
            <div className="mb-6">
              <Button onClick={handleUpload} disabled={isLoading} className="w-full bg-blue-600 hover:bg-blue-700 text-white">
                {isLoading ? 'Uploading...' : 'Upload File'}
              </Button>
            </div>
          )}

          {isLoading && (
            <Alert className="mb-6">
              <AlertTitle>Uploading...</AlertTitle>
              <AlertDescription>Please wait while we process your file.</AlertDescription>
            </Alert>
          )}

          {error && (
            <Alert className="mb-6" variant="destructive">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {uploadedFile && !isLoading && (
            <Alert className="mb-6" variant="success">
              <Upload className="h-4 w-4" />
              <AlertTitle>File Uploaded Successfully</AlertTitle>
              <AlertDescription>
                {uploadedFile.name} ({Math.round(uploadedFile.size / 1024)} KB)
              </AlertDescription>
            </Alert>
          )}
          
          {fileLink && (
            <div className="mb-6">
              <p className="mb-2 font-medium">Your file is available at:</p>
              <div className="flex items-center mb-2">
                <Input value={fileLink} readOnly className="flex-grow mr-2 bg-gray-50" />
                <Button onClick={handleCopy} className="min-w-[80px]">
                  {copied ? <><Check className="mr-1 h-4 w-4" /> copied!</> : 'copy'}
                </Button>
              </div>
              <div className="flex justify-between">
                <Button className="bg-blue-600 hover:bg-blue-700 text-white" onClick={handlePreview}>
                  <Eye className="mr-2 h-4 w-4" /> Preview
                </Button>
                <Button className="bg-green-600 hover:bg-green-700 text-white" onClick={handleDownload}>
                  <Download className="mr-2 h-4 w-4" /> Download
                </Button>
              </div>
            </div>
          )}
        </div>
      </main>
      
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto px-4 flex justify-center space-x-6">
          <a href="https://github.com/ojaydev" className="hover:text-blue-400 transition-colors">
            <Github size={24} />
          </a>
          <a href="https://twitter.com/dev_ojay" className="hover:text-blue-400 transition-colors">
            <Twitter size={24} />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default App;